import React, {useState} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import { send, init, sendForm } from 'emailjs-com';
import ReactModalAdapter from "../../helpers/ReactModalAdapter";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`mt-80 fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`w-1/3 h-1/3 xl:mx-auto m-4 sm:m-16  max-w-screen-xl absolute inset-0 text-white flex justify-center items-center rounded-lg bg-primary-800 outline-none`}
  }
  .mainHeroModal__text {
    ${tw`w-full lg:p-16 text-xl`}
  }
`;

const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;

export default ({
  subheading = "Contact Us",
  heading = <>Feel free to <span tw="text-primary-500">get in touch</span><wbr/> with us.</>,
  description = "Do you have any questions or suggestions about our services. Feel free to write us. You will be surprised how fast we will respond :)",
  submitButtonText = "Send",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.


  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const formElements = form.elements;
   
    toggleModal();

      init('user_VIa13DxkXWQ3GLrz6CxDV');
    send(
      'service_wfuio0k',
      'template_lutlw6q',
       {
         //'name': formElements.name.value,
        // 'subject' : formElements.subject.value,
       'message': formElements.message.value,
      'email' :formElements.email.value}
    )
      .then((response) => {
       console.log('SUCCESS!', response.status, response.text);
     
      })
      .catch((err) => {
      
        console.log('FAILED...', err);
      });
     
  }

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleModal = () => setModalIsOpen(!modalIsOpen);


  return (
    <Container name="contactformcontainer">
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form onSubmit={handleSubmit} action={formAction} method={formMethod}>
              <Input type="email" name="email" placeholder="Your Email Address" />
              {/* <Input type="text" name="name" placeholder="Name" /> */}
              {/* <Input type="text" name="subject" placeholder="Subject" /> */}
              <Textarea name="message" placeholder="Your Message Here" />
              <SubmitButton type="submit">{submitButtonText}</SubmitButton>
            </Form>
            <StyledModal
          closeTimeoutMS={300}
          className="mainHeroModal"
          isOpen={modalIsOpen}
          onRequestClose={toggleModal}
          shouldCloseOnOverlayClick={true}
        >
           <CloseModalButton onClick={toggleModal}>
            <CloseIcon tw="w-6 h-6" />
          </CloseModalButton>
          <div className="mainHeroModal__text">
              <TextContent>We got your message. You will have a response soon.</TextContent> 
          </div>
          </StyledModal>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
