import React, { useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
import Card from "./Card.js";
import Button from "./Button.js";
import LoadingSpinner from "./LoadingSpinner";

import { useLocation } from "react-router-dom";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  const [contract, setContract] = useState(null);
  const [agree, setAgree] = useState(false);
  const [loading, setLoading] = useState(true);

  const search = useLocation().search;
  const contractId = new URLSearchParams(search).get("c");

  const getContractInfo = async () => {
    setLoading(true);
    const response = await fetch(`https://servistesin.herokuapp.com/api/rtkghdedbauethnndwtb/${contractId}`);
    const data = await response.json();
    setContract(data.contract);
    setLoading(false);
  };

  const checkboxHandler = () => {
    // if agree === true, it will be set to false
    // if agree === false, it will be set to true
    setAgree(!agree);
    // Don't miss the exclamation mark
  };

  // When the button is clicked
  const btnHandler = async () => {
    try {
      setLoading(true);
      const responseData = await fetch(`https://servistesin.herokuapp.com/api/rtkghdedbauethnndwtb/${contractId}`, {
        method: "PATCH",
      });
    } catch (error) {
    }

    getContractInfo();
  };

  useEffect(() => {
    getContractInfo();
  }, []);

  const rawHTML = `
  
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;color:black;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;font-family:"Times New Roman",serif;'>MADDE 1 - TARAFLAR&nbsp;</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;font-family:"Times New Roman",serif;'>1.1</span></strong><span style='font-size:16px;font-family:"Times New Roman",serif;'>&nbsp; &nbsp; &nbsp; &nbsp;&Ccedil;ETİNTUR KARDEŞLER SERVİS HİZMETLERİ TURİZM TAŞIMACILIK LTD.ŞTİ (bundan b&ouml;yle <strong>Firma&nbsp;</strong>olarak anılacaktır.)</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;font-family:"Times New Roman",serif;'>Tarım Mah. 1613 Sok. No:40 Muratpaşa / ANTALYA</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;font-family:"Times New Roman",serif;'>2.2</span></strong><span style='font-size:16px;font-family:"Times New Roman",serif;'>&nbsp; &nbsp; &nbsp; &nbsp;VELİ/LER&nbsp; &nbsp; &nbsp;&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>

<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>*</span></strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>&nbsp;Yukarıdaki bilgilere sahip Kişi ve Kişiler (bundan b&ouml;yle <strong>Veli&nbsp;</strong>olarak anılacaktır.)</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>**Firma ve Veli topluca &ldquo;Taraflar&rdquo; olarak ve tek tek &ldquo;Taraf&rdquo; olarak anılacaklardır.<strong>&nbsp; &nbsp; &nbsp;&nbsp;</strong></span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>MADDE 2 - S&Ouml;ZLEŞMENİN KONUSU&nbsp;</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>2.1&nbsp; &nbsp; &nbsp; &nbsp;</span></strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>İş bu s&ouml;zleşmenin konusu, Firma&rsquo;nın s&ouml;zleşme yaptığı ve Veli&rsquo;nin &ouml;ğrencisinin kayıtlı olduğu okuldan, okul<span style="color:black;">&nbsp;servis ara&ccedil;ları hizmet y&ouml;netmeliği h&uuml;k&uuml;mlerine uygun olarak &ouml;ğrencinin veli tarafından belirlenmiş adresinden alınıp okula bırakılması ve okuldan alınarak adresine bırakılması konusunda &Ouml;ğrenci Servis Taşıma Hizmeti verilmesidir.</span>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>MADDE 3 - S&Ouml;ZLEŞME S&Uuml;RESİ&nbsp;</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>3.2</span></strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>&nbsp; &nbsp; &nbsp; &nbsp;İş bu s&ouml;zleşme yukarıda belirtilen &ouml;ğretim yıllarını kapsamakta olup, yasal okul a&ccedil;ılış tarihi ile kapanış tarihleri arasında ge&ccedil;erli olacaktır. Ancak Firma ile Veli arasında yapılacak yazılı mutabakat ile s&ouml;zleşme s&uuml;resi uzatılıp, kısaltılabilir. Uzama halinde tarafların diğer y&uuml;k&uuml;ml&uuml;l&uuml;kleri baki kalmak kaydı ile bu s&ouml;zleşmede belirtilen &uuml;cretler ge&ccedil;erli olmayacak, taraflarca yeni bir &uuml;cret kararlaştırılacaktır.&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>MADDE 4 - TARAFLARIN Y&Uuml;K&Uuml;ML&Uuml;L&Uuml;KLERİ&nbsp;</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;color:black;'>4.1</span></strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;color:black;'>&nbsp; &nbsp; &nbsp; &nbsp;Taraflar, servis aracına &ouml;ğrenci ve &ouml;ğretmenlerden başka herhangi bir kimse alınmayacağı hususunda mutabıktırlar.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;color:black;'>4.2&nbsp; &nbsp; &nbsp; &nbsp;</span></strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;color:black;'>Firma, &ouml;ğrencinin adresinden alınarak okula bırakılması ve okuldan alınarak adresine bırakılmasından sorumludur. Firma bu hizmeti sunarken Veli&rsquo;yi her t&uuml;rl&uuml; iletişim kanalları ile bilgilendirme hakkına sahip olup, telefon aramaları hari&ccedil; diğer bilgilendirmelerin bedelini talep etme hakkına sahiptir. &Ouml;ğrencinin saatinde servisin alacağı yerde ya da okulda olmaması ve &ouml;ğrenciye o an ulaşılamaması durumunda şirketin herhangi bir yasal sorumluluğu olmayıp şirketin bu anlamdaki yegane sorumluluğu veliye haber vermekten ibarettir.&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;color:black;'>4.3</span></strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;color:black;'>&nbsp; &nbsp; &nbsp; &nbsp;Firma, hizmetin sunumunu okul servis ara&ccedil;ları hizmet y&ouml;netmeliği standartlarına uygun bir şekilde g&uuml;venilir ve kaliteli yapmakla sorumludur.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>4.4</span></strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>&nbsp; &nbsp; &nbsp; &nbsp;Veli, &ouml;ğrencinin yukarıda belirtilen adresten alınıp okul &ccedil;ıkışında aynı adrese bırakılmasını kabul ve beyan etmiştir. Veli, &ouml;ğrenciyi kendisi okuldan almak istemesi halinde veya servis g&uuml;zergahında bulunan farklı bir adrese bırakılmasını talep etmesi halinde durumu yazılı olarak Firmanın ulaşım m&uuml;d&uuml;r&uuml; ve servis s&uuml;r&uuml;c&uuml;s&uuml;ne (bu hususta belirtilen kişileri okul y&ouml;netimi veya firmayı arayarak ulaşabilir) bildirecektir. Farklı adrese bırakma talebi, yalnızca bildirilen adresin servis g&uuml;zergahında bulunması durumunda kabul g&ouml;recek olup servisin, &ouml;ğrenciyi g&uuml;zergah dışı bir adrese bırakma y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml; bulunmamaktadır.&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>4.5</span></strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>&nbsp; &nbsp; &nbsp; &nbsp;Veli, &ouml;ğrencisinin ve kendisinin Servis hizmeti sunumu esnasında okul idaresi ve Firma&rsquo;nın koymuş olduğu ilgili y&ouml;netmelik harici kurallara uyacağını kabul ve beyan eder.&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>4.6</span></strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>&nbsp; &nbsp; &nbsp; &nbsp;Veli, &ouml;ğrencinin servis aracına ve serviste ge&ccedil;irdiği s&uuml;re i&ccedil;erisinde 3. kişilere vereceği her t&uuml;rl&uuml; maddi ve manevi zararlardan sorumlu olduğunu da kabul ve beyan eder. Şirket tarafından, &ouml;ğrencinin verdiği zarar nedeniyle herhangi bir &ouml;deme yapılmak zorunda kalınması halinde, şirketin bu zararını veli tanzim edecektir.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>4.7</span></strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>&nbsp; &nbsp; &nbsp; &nbsp;Veli, Firma&rsquo;nın sunduğu hizmetin tanıtımı amacıyla &ouml;ğrencinin ve kendisinin g&ouml;rsellerini bila bedel kullanılmasını, kendisine bu hususta KVKK anlamında gerekli bilgilerin verildiğini, her t&uuml;rl&uuml; bilgilendirme akabinde bu kullanımı kabul ettiğini kabul ve beyan eder.&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>4.8</span></strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>&nbsp; &nbsp; &nbsp; &nbsp;Firma, Veli ve &ouml;ğrencinin iletişim bilgilerini sadece kamu makamlarından gelen taleplerde paylaşacağını kabul ve beyan eder. &nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>4.9</span></strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>&nbsp; &nbsp; &nbsp; &nbsp;Firma, s&ouml;zleşme s&uuml;resi boyunca hizmet verdiği; servis aracında, s&uuml;r&uuml;c&uuml;s&uuml;nde, rehber personelinde değişiklik yapabilir. Veli s&uuml;r&uuml;c&uuml; veya personelin değişmesi i&ccedil;in şirketi zorlayamaz.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>MADDE 5- S&Ouml;ZLEŞME BEDELİ VE &Ouml;DEME ŞEKLİ</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>5.1</span></strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>&nbsp; &nbsp; &nbsp; &nbsp;Veli, Firma’ya toplamda sözleşme süresi için belirtilen TL (KDV DAHİL) ödemeyi yapacaktır. Asgari &uuml;cret, mazot, benzin ve sair giderlerde artış olması durumunda, şirket tarafından gider artışı oranında servis &uuml;creti artırımı yapılabilir. Bu husus veli tarafından peşinen kabul edilmiştir.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>5.2</span></strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>&nbsp; &nbsp; &nbsp; &nbsp;Veli, toplam bedeli tek seferde kredi kartıyla/nakden/banka hesabına havale/EFT ile yada kredi kartıyla en fazla 9 taksitli &ccedil;ekim yaparak &ouml;deyecektir. Veli tarafından gereken şekillerde &ouml;deme yapılmaması halinde, servis &ouml;ğrenciyi almaktan imtina edebilir. Veli &ouml;deme yapmaması halinde, &ouml;ğrencinin servise alınmayacağını kabul, beyan ve taahh&uuml;t eder.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>5.3</span></strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>&nbsp; &nbsp; &nbsp; &nbsp;S&ouml;zleşme feshi durumlarında, hizmet verilen s&uuml;reler hesaplanıp d&uuml;ş&uuml;ld&uuml;kten sonra kalan tutardan % 10 fesih bedeli de kesilerek, tahsilat yapılan &ouml;demek şekli ile iadesi yapılacaktır.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>5.4</span></strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>&nbsp; &nbsp; &nbsp; &nbsp;Adres değişikliğinden doğan &uuml;cret farkları değişiklik tarihinden itibaren ge&ccedil;erli olarak yeniden d&uuml;zenlenir. Adres değişikliklerinde Firma hizmet vermeme hakkına sahip olduğu gibi taraflarca daha &ouml;nce kararlaştırılan bedelin değiştirilmesine de karar verebilir. Taraflarca bedel &uuml;zerinde anlaşılamaz ise s&ouml;zleşme 5.3. maddede belirtilen şartlar ile feshedilebilecektir. &nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>5.5</span></strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>&nbsp; &nbsp; &nbsp; &nbsp;Firma, tek y&ouml;nl&uuml; hizmet sunumunda &ouml;ğrenci i&ccedil;in bir koltuk ayırdığından tam &uuml;cret alma hakkına sahiptir. &Ouml;ğrencinin devamsızlık yapması, raporlu olması, okul sonrası etkinliklere katılması ve sair herhangi bir nedenle okula gitmemesi ya da okuldan d&ouml;nmemesi halinde de &uuml;cret tam olarak &ouml;denir. Servis &uuml;cretinde kesinti yapılmaz.&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>5.6 &nbsp; &nbsp; &nbsp;&nbsp;</span></strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>Servis kapasitelerindeki &ouml;nlem ama&ccedil;lı yapılacak azaltmalara y&ouml;nelik artacak maliyetler&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>Veliden tahsil edilen tutarlara ve s&ouml;zleşme bedellerine taşıma firması tarafından belirlenen oranda ek olarak fiyat farkı yansıtılacaktır. Veli fiyat farkı tutarını &ouml;demekle y&uuml;k&uuml;ml&uuml;d&uuml;r.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>5.7&nbsp; &nbsp; &nbsp; &nbsp;</span></strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>S&ouml;zleşme bedeline<strong>&nbsp;</strong>Servistesin veli bilgilendirme ve servis g&uuml;venliği sistemi dahildir.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>MADDE 6 &ndash; VELİ&rsquo;NİN TAAHH&Uuml;D&Uuml;&nbsp;</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>6.1</span></strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>&nbsp; &nbsp; &nbsp; &nbsp;Firma&rsquo;nın Yazılım Firmaları ile gerek mevzuat, gerekse g&uuml;venli ve kontrol edilebilir &ouml;ğrenci taşımacılığı ilkesi gereği, yapmış olduğu uygulama anlaşmaları &ccedil;er&ccedil;evesinde Veli; Firma tarafından ara&ccedil; i&ccedil;inde bulunan t&uuml;m &nbsp;kullanıcıların (Ara&ccedil;ta bulunan diğer &ouml;ğrenci velilerinin) veya okul y&ouml;netiminin talep etmesi durumunda &nbsp;ara&ccedil; i&ccedil;inde canlı yayında online g&ouml;r&uuml;nt&uuml; kaydedilmesi ve izlenmesi, su&ccedil;a konu bir vakanın olması durumunda da kamu yetkililerine g&ouml;r&uuml;nt&uuml; ve kayıtların verilmesine şimdiden muvafakat eder. Bu konuda gerek Yazılım Firması, gerekse Firma&rsquo;nın kendisinden hi&ccedil;bir hak ve talepte bulunmayacağını kabul, beyan ve taahh&uuml;t eder.&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>6.2</span></strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>&nbsp; &nbsp; &nbsp; &nbsp;Veli, Firma&rsquo;nın Yazılım Firması ile yapmış olduğu anlaşmalar gereği canlı g&ouml;r&uuml;nt&uuml; kaydetme ve izleme sistemin kullanımı esnasında kullanıcıların aplikasyon &uuml;zerinden reklam alması ve bu hizmetin kullanılması esnasında aplikasyon &uuml;zerinden 3.kişi veya kurumların reklam bilgisi g&ouml;nderebileceğini, KVKK anlamında bilgilendirildiğini ve reklam ama&ccedil;lı olarak &ccedil;eşitli g&ouml;r&uuml;nt&uuml;lerin kullanılabileceğini kabul, beyan ve taahh&uuml;t eder.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>MADDE 7 - S&Ouml;ZLEŞME FESHİ</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>7.1</span></strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>&nbsp; &nbsp; &nbsp; &nbsp;Velinin, yukarıda belirtilen &ouml;deme miktarı ve &ouml;deme koşullarını yerine getirmemesi durumunda, Firma servis hizmetini durdurma hakkı ile s&ouml;zleşmeyi tek taraflı fesh etme hakkına sahiptir. Veli bu durumda &ouml;ğrencinin eğitim hakkının kısıtlandığını ileri s&uuml;remez.&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>7.2&nbsp; &nbsp; &nbsp; &nbsp;</span></strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>Taraflardan herhangi biri, 5. Maddenin tamamı ve 7.1 maddesi dışında yer alan y&uuml;k&uuml;ml&uuml;l&uuml;klerini yerine getirmemesi durumunda karşı taraf yazılı bilgi verecek ve y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;n yerine getirilmesini isteyecektir. Bu ihtara rağmen y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;n yerine getirilmemesi halinde ise ihtar eden taraf s&ouml;zleşmeyi tek taraflı fesh etme hakkına sahiptir.&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>MADDE 8 &ndash; DİĞER H&Uuml;K&Uuml;MLER</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>8.1&nbsp; &nbsp; &nbsp; &nbsp;</span></strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>Maliyet artışı nedeniyle &uuml;cret değişikleri hari&ccedil; olmak kaydı ile bu s&ouml;zleşme&rsquo;de yapılacak her t&uuml;rl&uuml; değişiklik, Taraflar arasında ancak yazılı olarak kararlaştırılması halinde h&uuml;k&uuml;m ifade eder.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>8.2</span></strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>&nbsp; &nbsp; &nbsp; &nbsp;Tarafların tebligat adresleri, S&ouml;zleşme&rsquo;nin başında belirtilen adreslerdir. Taraflardan biri diğerine yazılı olarak noter vasıtası ile bu tebligat adresinin değiştirdiğini değişiklikten 7 (yedi) iş g&uuml;n&uuml; i&ccedil;inde bildirmediği s&uuml;rece bu adreslere yapılan tebligatlar kendilerine yapılmış sayılacaktır. Velinin yukarıda belirtilen telefon ve mail adreslerine sms yahut e-posta yolu ile yapılan bildirimler usul&uuml;nce yapılmış olarak kabul edilecektir.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>8.3</span></strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>&nbsp; &nbsp; &nbsp; &nbsp;S&ouml;zleşmedeki maddelerden herhangi birinin ge&ccedil;erli olmaması hali, s&ouml;zleşmenin ge&ccedil;erliliğine etki etmez.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>8.4</span></strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>&nbsp; &nbsp; &nbsp; &nbsp;S&ouml;zleşme&rsquo;ye ait damga vergisi, resim ve har&ccedil;lar Veli tarafından karşılanacaktır.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>8.5</span></strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>&nbsp; &nbsp; &nbsp; &nbsp;İşbu s&ouml;zleşmenin uygulanması nedeniyle doğacak her t&uuml;rl&uuml; uyuşmazlıkta bu s&ouml;zleşme ile birlikte İşverene ait defter ve kayıtlar esas alınacaktır. Yazılı delil anlaşması niteliğindeki bu maddenin aksine bir delil ikame edilemez.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>8.6</span></strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>&nbsp; &nbsp; &nbsp; &nbsp;S&ouml;zleşme ihtilaflarından doğan hukuki veya icrai uyuşmazlık halinde Antalya Merkez Mahkemeleri ve İcra Daireleri yetkili olacaktır.&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>Bu 8 maddeden ibaret s&ouml;zleşme taraflarca tartışılmak ve m&uuml;zakere edilmek sureti ile okunmuş, anlaşılmış ve kabul edilerek yukarıdaki tarihte elektronik olarak onaylanmıştır.&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>MADDE 9 YÜRÜRLÜLÜK</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>9.1&nbsp; &nbsp; &nbsp; &nbsp;</span></strong><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>9 (dokuz) maddeden ibaret Sözleşme ,Taraflar ‘ca okunarak, işlem tarihinde, Veli tarafından
elektronik ortamda onaylanmak suretiyle akdedilmiş ve yürürlüğe girmiştir. Sözleşme ‘nin bir nüshası
Servistesin sistemi veri tabanında mevcuttur ve talep edilmesi halinde ayrıca elektronik posta ile de
gönderilebilecektir.</span></p>
  `;

  if (loading) {
    return (
      <Card className="userapproval">
        <h1>ÖĞRENCİ TAŞIMA SÖZLEŞMESİ</h1>
        <LoadingSpinner />
      </Card>
    );
  }

  return (
    <React.Fragment>
      {contract && !contract.userApproved && (
        <Card className="userapproval">
          <div>
            <h1>ÖĞRENCİ TAŞIMA SÖZLEŞMESİ</h1>
            <div style={{textAlign: "left"}}>
              <input type="checkbox" id="agree" onChange={checkboxHandler} />
              <label htmlFor="agree">
                {" "}
                Aşağıdaki <b>Öğrenci Taşıma Sözleşmesini</b> okudum, sözleşme hükümlerini ve şartlarını kabul ediyorum.
              </label>
            </div>

            {/* Don't miss the exclamation mark* */}
            <br />
            <Button disabled={!agree} onClick={btnHandler}>
              Onayla
            </Button>
            <br />
            <br />

            <div style={{ visibility: "inherit" }}>
              <div style={{ textAlign: "left" }}>
                <div>
                  {" "}
                  <b>İsim:</b> {contract.payerName}
                </div>
                <div>
                  {" "}
                  <b> Sözleşme Tarihi: </b> {contract.date}
                </div>
                <div>
                  {" "}
                  <b> Hizmet Dönemi: </b> 2023-2024 Eğitim Öğretim Yılı
                </div>
                <div>
                  {" "}
                  <b> Sözleşme Bedeli:</b> {contract.totalAmount} TL
                </div>
              </div>

              <br />

              <div dangerouslySetInnerHTML={{ __html: rawHTML }}></div>
            </div>
          </div>
        </Card>
      )}

      {contract && contract.userApproved && (
        <Card className="userapproval">
          <div>
            <h1>Sözleşmeniz onaylanmıştır.</h1>
          </div>
        </Card>
      )}
    </React.Fragment>
  );
};
